import { default as usersMnNQOGKhY3Meta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue?macro=true";
import { default as application22BvZiAPYbMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue?macro=true";
import { default as attachmentsVX3ajOjBqeMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue?macro=true";
import { default as contactCxyDeiBoSOMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue?macro=true";
import { default as prescreenVC0YUm8LtZMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue?macro=true";
import { default as scholarshipnbqXmZrsJCMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue?macro=true";
import { default as summaryZVc9IwHOL1Meta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue?macro=true";
import { default as casenw317MJfiGMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case.vue?macro=true";
import { default as case_45assignments0n8F4IVP8aMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue?macro=true";
import { default as income_45eligibilitys7OFcpHarGMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/income-eligibility.vue?macro=true";
import { default as reportsbFIAO6zDvsMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/reports.vue?macro=true";
import { default as indexl9Br4PtV1CMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/index.vue?macro=true";
import { default as new_45case7q2PCi7logMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/new-case.vue?macro=true";
import { default as providerlistyhbM3hwfbZMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue?macro=true";
import { default as eligibilityi1xAgFSg7dMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue?macro=true";
import { default as _91id_93HnhyyMW4qqMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue?macro=true";
import { default as searchEEl5XpEZzuMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/search.vue?macro=true";
import { default as indexAg3nQy7xoXMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue?macro=true";
import { default as notifications31PIoZEVjUMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue?macro=true";
import { default as settingsGl6CF26knGMeta } from "/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersMnNQOGKhY3Meta || {},
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case.vue").then(m => m.default || m),
    children: [
  {
    name: "case-id-application",
    path: ":id()/application",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: "case-id-attachments",
    path: ":id()/attachments",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "case-id-contact",
    path: ":id()/contact",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: "case-id-prescreen",
    path: ":id()/prescreen",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue").then(m => m.default || m)
  },
  {
    name: "case-id-scholarship",
    path: ":id()/scholarship",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue").then(m => m.default || m)
  },
  {
    name: "case-id-summary",
    path: ":id()/summary",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "csp-admin-case-assignments",
    path: "/csp-admin/case-assignments",
    meta: case_45assignments0n8F4IVP8aMeta || {},
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue").then(m => m.default || m)
  },
  {
    name: "csp-admin-income-eligibility",
    path: "/csp-admin/income-eligibility",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/income-eligibility.vue").then(m => m.default || m)
  },
  {
    name: "csp-admin-reports",
    path: "/csp-admin/reports",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/csp-admin/reports.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-case",
    path: "/new-case",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/new-case.vue").then(m => m.default || m)
  },
  {
    name: "providerlist",
    path: "/providerlist",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue").then(m => m.default || m)
  },
  {
    name: "public-csp-eligibility",
    path: "/apply/childcare/eligibility",
    meta: eligibilityi1xAgFSg7dMeta || {},
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue").then(m => m.default || m)
  },
  {
    name: "report-id",
    path: "/report/:id()",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingsGl6CF26knGMeta?.name,
    path: "/settings",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/codebuild/output/src2430001572/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  }
]